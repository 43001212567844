<template>
  <div class="Login">
    <q-form class="fixed-center shadow-10" @submit="onSubmit">
      <div class="title text-center text-white text-h6 bg-grey-5">
        Novo Usuario
      </div>
      <div class=" q-pa-md">
        <q-input
          v-model="form.nickName"
          type="text"
          label="Como quer ser chamado?"
        />

        <q-input
          v-model="form.email"
          type="email"
          label="Qual é o seu e-mail"
        />

        <q-input
          v-model="form.password"
          :type="isPwd ? 'password' : 'text'"
          label="Informe uma senha"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            />
          </template>
        </q-input>

        <div class="row justify-between q-mt-md q-mx-md">
          <q-btn
            flat
            class="col-3"
            label="Voltar"
            color="primary"
            @click="back"
          />
          <q-btn
            flat
            class="col-4"
            label="Cadastrar"
            type="submit"
            color="positive"
          />
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { signUp } from "@/services/Auth";
export default {
  data() {
    return {
      form: {
        email: null,
        password: null,
        nickName: null
      },
      isPwd: true
    };
  },
  methods: {
    async onSubmit() {
      try {
        await signUp(this.form);
        this.$router.push({ name: "Home" });
      } catch (error) {
        let message = "Houve algum erro ao enviar o cadastro.";
        if (
          error.message ===
          "The email address is already in use by another account."
        ) {
          message = "O endereço de e-mail já está cadastrado.";
        }
        this.$q.notify({
          color: "red",
          textColor: "white",
          message,
          icon: "announcement",
          position: "top"
        });
      }
    },
    back() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss">
.Login {
  width: 100%;
  height: 100%;
}
</style>
